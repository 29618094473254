import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";

const HomeButton: FunctionComponent = () => {
  const navigate = useNavigate();
  return (
    <div>
      <button style={{ margin: "50px" }} onClick={() => navigate("/")}>
        Back to Home
      </button>
    </div>
  );
};

export default HomeButton;
