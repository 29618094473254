import { IApplicantResponse } from "models/applicant";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from "react";

export interface IUserInfo {
  backup_id: string;
}

type UserContextType = {
  userInfo: IUserInfo;
  applicant: IApplicantResponse;
  updateUserInfo: (userInfo: IUserInfo) => void;
  updateApplicant: (applicant: IApplicantResponse) => void;
};

export const userContextDefaultValues: UserContextType = {
  userInfo: {
    backup_id: "",
  },
  applicant: { sdk_token: "" },
  updateUserInfo: () => void 0,
  updateApplicant: () => void 0,
};

const UserContext = createContext<UserContextType>(userContextDefaultValues);

/**
 *
 * @returns
 */
function useUser() {
  return useContext(UserContext);
}

type Props = {
  children: ReactNode;
};

/**
 *
 * @param children - Children components that would inherit the context
 * @returns
 */
function UserProvider({ children }: Props) {
  const [userInfo, setUserInfo] = useState<IUserInfo>({
    ...userContextDefaultValues.userInfo,
  });

  const [applicant, setApplicant] = useState<IApplicantResponse>({
    ...userContextDefaultValues.applicant,
  });

  const updateUserInfo = useCallback((userInfo: IUserInfo) => {
    setUserInfo((prevUserInfo) => {
      return { ...prevUserInfo, ...userInfo };
    });
  }, []);

  const updateApplicant = useCallback((applicant: IApplicantResponse) => {
    setApplicant((prevApplicant) => {
      return { ...prevApplicant, ...applicant };
    });
  }, []);

  return (
    <UserContext.Provider
      value={{ userInfo, applicant, updateUserInfo, updateApplicant }}
    >
      {children}
    </UserContext.Provider>
  );
}

export { useUser, UserProvider, UserContext };
