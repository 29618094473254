export const post = async (
  path: string,
  body?: any,
  token?: string
): Promise<any> => {
  const headers: any = {
    "Content-Type": "application/json",
  };
  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }
  headers["x-access-token"] = `${process.env["REACT_APP_API_TOKEN"]}`;
  const response = await fetch(`${process.env["REACT_APP_API_URL"]}${path}`, {
    method: "POST",
    headers,
    body,
  });
  let json: any;
  try {
    json = await response.json();
  } catch {
    return response.status;
  }
  if (!response.ok) {
    const error: any = new Error(json.error);
    error.info = json;
    error.status = response.status;

    throw error;
  }
  return json;
};

export const get = async <T = any>(
  path: string,
  token?: string
): Promise<T> => {
  const headers: any = {
    "Content-Type": "application/json",
  };
  headers["x-access-token"] = `${process.env["REACT_APP_API_TOKEN"]}`;
  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }

  const response = await fetch(`${process.env["REACT_APP_API_URL"]}${path}`, {
    method: "GET",
    headers,
  });
  const json = await response.json();

  // We must throw if response is not ok, so react-query can catch the error.
  if (!response.ok) {
    const error: any = new Error("An error occurred while fetching the data.");
    error.info = json;
    error.status = response.status;

    throw error;
  }
  return json;
};

export const apiDelete = async (
  path: string,
  token?: string
): Promise<boolean> => {
  const headers: any = {
    "Content-Type": "application/json",
  };
  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }
  headers["x-access-token"] = `${process.env["REACT_APP_API_TOKEN"]}`;
  const response = await fetch(`${process.env["REACT_APP_API_URL"]}${path}`, {
    method: "DELETE",
    headers,
  });

  // We must throw if response is not ok, so react-query can catch the error.
  if (!response.ok) {
    const error: any = new Error("An error occurred while fetching the data.");
    error.status = response.status;

    throw error;
  }
  return response.ok;
};
