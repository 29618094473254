import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";

interface Props {
  show_onfido?: boolean;
}

const SelectFlow: FunctionComponent<Props> = () => {
  const navigate = useNavigate();
  return (
    <div style={{ margin: "50px" }}>
      <p>Please select which flow you want to do</p>
      <button style={{ margin: "5px" }} onClick={() => navigate("/activation")}>
        Activation
      </button>
      <button style={{ margin: "5px" }} onClick={() => navigate("/restore")}>
        Restore
      </button>
    </div>
  );
};

export default SelectFlow;
