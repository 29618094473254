import { IUserInfo } from "context/User/User.context";
import { IApplicantResponse } from "models/applicant";
import { get, post } from "../fetch";

const callbackBody = JSON.stringify({
  callback_href: "protect-idv-gateway-ledger-tessi.aws.stg.ldg-tech.com",
});

export const startKYCCheck = async (backupId: string) => {
  return post(`/session/${backupId}/check`, callbackBody);
};

export const startKYCRestoreCheck = async (backupId: string) => {
  return post(`/session/${backupId}/restore`, callbackBody);
};

export const createApplicant = async (
  body: IUserInfo
): Promise<IApplicantResponse> => {
  return post("/session", JSON.stringify(body));
};

export const getApplicant = async (
  backup_id: string
): Promise<IApplicantResponse> => {
  try {
    const response = await get(`/session/${backup_id}`);
    return response;
  } catch (error) {
    return {
      sdk_token: "not_exist",
    };
  }
};
