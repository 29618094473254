import React, { useEffect, useRef } from "react";

import type { SdkInitMethod, SdkOptions, SdkHandle } from "onfido-sdk-ui";
import { startKYCCheck, startKYCRestoreCheck } from "services/KYC";
import { useStatus } from "context/Status/Status.context";
import { useUser } from "context/User/User.context";
import { FlowType } from "models/flow";

interface Props {
  flowType: FlowType;
  onfidoToken?: string;
  isOpen?: boolean;
  onComplete?: () => void;
  onClose?: () => void;
  hasLoaded?: () => void;
}

function setOnfidoForcedCrossDevice() {
  if (`${process.env["REACT_APP_ONFIDO_FORCE_MOBILE"]}` === "disabled") {
    return false;
  } else {
    return true;
  }
}

const steps: SdkOptions["steps"] = [
  "welcome",
  {
    type: 'document',
    options: {    
      forceCrossDevice: setOnfidoForcedCrossDevice(),
    }
  },
  {
    type: 'face',   
    options: {
      requestedVariant: 'video',
    }
  },
  {
    type: "face",
    options: {
      requestedVariant: "video",
      uploadFallback: false,
    },
  },
  "complete",
];
const Onfido = ({
  flowType,
  onfidoToken,
  isOpen,
  onClose,
  onComplete,
  hasLoaded,
}: Props) => {
  const OnfidoHandle = useRef<SdkHandle>();
  const { addUpdate } = useStatus();
  const { userInfo } = useUser();

  useEffect(() => {
    let Onfido: SdkInitMethod;
    async function getOnfido() {
      Onfido = (await import("onfido-sdk-ui")).init;
    }
    if (onfidoToken && OnfidoHandle.current === undefined) {
      getOnfido()
        .then(() => {
          OnfidoHandle.current = Onfido({
            steps,
            token: onfidoToken,
            containerId: "onfido-mount",
            useModal: true,
            isModalOpen: false,
            crossDeviceClientIntroProductName: "",
            crossDeviceClientIntroProductLogoSrc: "",
            onModalRequestClose: function () {
              onClose && onClose();
            },
            onComplete: async function () {
              const token = OnfidoHandle.current?.options.token;
              addUpdate("Upload Completed!");
              if (token) {
                addUpdate("Trigger Check!");
                const check =
                  flowType === "activation"
                    ? await startKYCCheck(userInfo.backup_id)
                    : await startKYCRestoreCheck(userInfo.backup_id);
                if (check === 403) {
                  addUpdate("HTTP 403 Forbidden: Already restored!");
                } else {
                  addUpdate(
                    `Check Finished! Response: ${JSON.stringify(check)}`
                  );
                }
                onComplete && onComplete();
              }
            },
          });
        })
        .then(() => {
          hasLoaded && hasLoaded();
        });
    }
  }, [
    onComplete,
    onfidoToken,
    onClose,
    hasLoaded,
    addUpdate,
    userInfo,
    flowType,
  ]);
  useEffect(() => {
    if (OnfidoHandle.current) {
      OnfidoHandle.current?.setOptions({ isModalOpen: isOpen, steps });
    }
  }, [isOpen]);
  return <div data-testid="onfido" id="onfido-mount"></div>;
};

export default Onfido;
