import React, { FunctionComponent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "context/User/User.context";
import { createApplicant, getApplicant } from "services/KYC";
import "./CreateApplicant.css";
import { useStatus } from "context/Status/Status.context";
import { FlowType } from "models/flow";
import HomeButton from "components/HomeButton";

interface Props {
  flowType: FlowType;
}

const CreateApplicant: FunctionComponent<Props> = ({ flowType }) => {
  const [backupId, setBackupId] = useState("");
  const [sent, setSent] = useState(false);
  const { userInfo, updateUserInfo, updateApplicant } = useUser();
  const navigate = useNavigate();
  const { addUpdate } = useStatus();

  const handleSubmit = async (evt: any) => {
    evt.preventDefault();
    setSent(true);
    addUpdate(`Submitting backup ${backupId}`);
    addUpdate("Create applicant");
    const userApplicant =
      flowType === "activation"
        ? await createApplicant(userInfo)
        : await getApplicant(userInfo.backup_id);

    if (userApplicant.sdk_token === "not_exist") {
      addUpdate(`
        Action forbidden.
        Possible reasons: applicant doesn't exist or activation flow wasn't succesful or applicant has already done a restore.`);
    } else {
      addUpdate("Applicant created");
      updateApplicant(userApplicant);
      navigate(`/${flowType}/kyc`);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <label>
          Backup ID:
          <input
            disabled={sent}
            className="Email"
            type="text"
            value={backupId}
            onChange={(e) => {
              setBackupId(e.target.value);
              updateUserInfo({ backup_id: e.target.value });
            }}
          />
        </label>
        <input
          disabled={sent || !backupId}
          type="submit"
          value={
            flowType === "activation" ? "Create Applicant" : "Get Applicant"
          }
        />
      </form>
      <HomeButton />
    </div>
  );
};

export default CreateApplicant;
