import React from "react";
import { useStatus } from "context/Status/Status.context";

function Updates() {
  const { updates } = useStatus();
  return (
    <div>
      {updates?.lines?.map((value, index) => {
        return <p key={index}>{value}</p>;
      })}
    </div>
  );
}

export default Updates;
