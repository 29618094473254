import CreateApplicant from "components/CreateApplicant";
import KYC from "components/KYC";
import { UserProvider } from "context/User/User.context";
import React from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { StatusProvider } from "context/Status/Status.context";
import Updates from "components/Updates";
import SelectFlow from "components/SelectFlow";

function App() {
  return (
    <div className="App">
      <UserProvider>
        <StatusProvider>
          <header className="App-header">
            <img src="/logo_cc.svg" className="App-logo" alt="logo" />
            <Updates></Updates>
            <Router>
              <Routes>
                <Route
                  path="/activation"
                  element={<CreateApplicant flowType="activation" />}
                ></Route>
                <Route
                  path="/activation/kyc"
                  element={<KYC flowType="activation" showOnfido={true} />}
                ></Route>

                <Route
                  path="/restore"
                  element={<CreateApplicant flowType="restore" />}
                ></Route>
                <Route
                  path="/restore/kyc"
                  element={<KYC flowType="restore" showOnfido={true} />}
                ></Route>

                <Route path="/" element={<SelectFlow />}></Route>
              </Routes>
            </Router>
          </header>
        </StatusProvider>
      </UserProvider>
    </div>
  );
}

export default App;
