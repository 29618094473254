import HomeButton from "components/HomeButton";
import { useStatus } from "context/Status/Status.context";
import { useUser } from "context/User/User.context";
import { FlowType } from "models/flow";
import React, { FunctionComponent, useState } from "react";
import Onfido from "../Onfido";

interface Props {
  showOnfido?: boolean;
  flowType: FlowType;
}

const KYC: FunctionComponent<Props> = ({ showOnfido, flowType }) => {
  const [isOnfidoCompleted, setIsOnfidoCompleted] = useState<boolean>(false);
  const [onfidoOpen, setOnfidoOpen] = useState<boolean>(false);
  const [onfidoLoaded, setOnfidoLoaded] = useState<boolean>(false);
  const { applicant } = useUser();
  const { addUpdate } = useStatus();

  return (
    <div data-testid="kyc">
      <Onfido
        flowType={flowType}
        onfidoToken={applicant.sdk_token}
        isOpen={onfidoOpen}
        onComplete={() => {
          setIsOnfidoCompleted(true);
        }}
        onClose={() => {
          setOnfidoOpen(false);
        }}
        hasLoaded={() => {
          setOnfidoLoaded(true);
        }}
      />
      {!isOnfidoCompleted ? (
        <button
          color="primary"
          disabled={!onfidoLoaded}
          onClick={() => {
            if (showOnfido) {
              addUpdate("Open onfido flow!");
              setOnfidoOpen(true);
            }
          }}
          data-testid="dynamicbtn"
        >
          Verify my identity
        </button>
      ) : (
        <div>All done now.</div>
      )}
      <HomeButton />
    </div>
  );
};

export default KYC;
