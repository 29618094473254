import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from "react";

type StatusContextType = {
  updates: {
    lines: string[];
  };
  addUpdate: (update: string) => void;
};

export const statusContextDefaultValues: StatusContextType = {
  updates: {
    lines: [
      "Starting the IDV flow will obtain an SDK token and trigger the check on completion.",
    ],
  },
  addUpdate: () => void 0,
};

const StatusContext = createContext<StatusContextType>(
  statusContextDefaultValues
);

/**
 *
 * @returns
 */
function useStatus() {
  return useContext(StatusContext);
}

type Props = {
  children: ReactNode;
};

/**
 *
 * @param children - Children components that would inherit the context
 * @returns
 */
function StatusProvider({ children }: Props) {
  const [updates, setUpdates] = useState<{ lines: string[] }>({
    ...statusContextDefaultValues.updates,
  });

  const addUpdate = useCallback((update: string) => {
    setUpdates((prevUpdates) => {
      prevUpdates.lines.push(update);
      return { ...prevUpdates };
    });
  }, []);

  return (
    <StatusContext.Provider value={{ updates, addUpdate }}>
      {children}
    </StatusContext.Provider>
  );
}

export { useStatus, StatusProvider, StatusContext };
